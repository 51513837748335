import $ from "jquery";
import 'jquery-validation';
import * as bootstrap from 'bootstrap';
import { start } from "@popperjs/core";

window.onload = (event) => {

	// Nav change
	if (document.getElementById('header') != null) {
		const headerNav = document.getElementById('header');
		window.addEventListener('scroll', function() {		
			if (window.scrollY > 0) {
				headerNav.classList.add('scrolled');
			} else {
				headerNav.classList.remove('scrolled');
			}
		});
	}

	// Anchor
	// const scrollAnchors = document.getElementsByClassName('nav-link');
	// for (let i = 0; i < scrollAnchors.length; i++ ) {
	// 	scrollAnchors[i].onclick = (event) => {
			
	// 		let section = document.getElementById(scrollAnchors[i].getAttribute('href').substring(1, scrollAnchors[i].getAttribute('href').length));
	// 		let newY = window.scrollY + (section.getBoundingClientRect().y - document.querySelector('.navbar').clientHeight - 80.);
	// 		window.scrollTo(0, newY);
			
	// 		event.preventDefault();
	// 		document.querySelector(this.getAttribute('href')).scrollIntoView({
	// 			behavior: 'smooth'
	// 		});
	// 	};
	// }


}

$(document).ready(function(){
	let url = 'https://www.finisherschutz.de';
	let form = $('#kaufenform');
	let total = 0;
	let betrag = 0;
	let amount = 0;

	$('input[name=inlineRadioOptions]').on('click', function(ev){
		console.log($(this).val() == 'true');
		if($(this).val() == 'true'){
			$('#veranstaltung').parent().parent().hide();
			$('#veranstaltung-datum').parent().parent().hide();
		}else{
			$('#veranstaltung').parent().parent().show();
			$('#veranstaltung-datum').parent().parent().show();
		}
	})

	$('#step2').on('click', function(){
		// let form = $('#kaufenform');
		
		form.validate({
			lang: 'de',
			rules: {
				veranstaltung: {
					required: true
				},
				'startgeld': {
                    required: true,
                    remote: url + '/Jsonhandler.ashx?what=validate'
                },
				'veranstaltung-datum' : {
					required : true,
					remote: url + '/Jsonhandler.ashx?what=validatedate'
				}
			},
			errorContainer: "#messageBox1",
            errorLabelContainer: "#messageBox1",
            wrapper: "li",
		});

		if(form.valid()){
			let offerTab = document.querySelector('#offer-tab')
			let tabOffer = new bootstrap.Tab(offerTab)		  
			tabOffer.show()

			let nameVeranstaltung = $('#veranstaltung').val();
			let datumVeranstaltung = $('#veranstaltung-datum').val();
			let startgeld = $('#startgeld').val();

			// Hier die Berechnung durchführen
			betrag = parseFloat(startgeld);      
			
			if(!isNaN(betrag)){                                    
				if(betrag > 600.00){
					$(this).val('600');
					betrag = 600.00;
				}
				total = betrag * 0.08;
				if(total < 2.5){
					total = 2.5;
				}
				total = total.toFixed(2);
				amount = total * 100;
				if(total.toString().indexOf('.') > -1){
					total = total.toString().replace('.', ',');
				}
			}


			$('#placeholderVeranstaltung').empty();
			$('#placeholderStartgeld').empty();
			$('#total').empty();
			$('#placeholderVeranstaltung').append('<strong>Abgesicherter Lauf</strong> <br />' + nameVeranstaltung +', '+ datumVeranstaltung);
			$('#placeholderStartgeld').append('<strong>Abgesichertes Startgeld</strong> <br />' + startgeld +' €');
			$('#total').append(total + ' €');
		}
		
	})

	$('#step1').on('click', function(){
		let offerTab = document.querySelector('#event-tab')
		let tabOffer = new bootstrap.Tab(offerTab)		  
		tabOffer.show()
	})
	
	$('#step3').on('click', function(){
		let offerTab = document.querySelector('#data-tab')
		let tabOffer = new bootstrap.Tab(offerTab)		  
		tabOffer.show()
		
	})

	$('#step3back').on('click', function(){
		let offerTab = document.querySelector('#offer-tab')
		let tabOffer = new bootstrap.Tab(offerTab)		  
		tabOffer.show()
		
	})


	$('#finish').on('click', function(){
		let form = $('#perdata');
		form.validate({
			lang: 'de',
			rules: {
				geburtsdatum: {
					required: true,
					remote: url + '/JsonHandler.ashx?what=validatedate'
				},
				ort: {
                    required: true,
                    minlength: 2
                },
                name: {
                    required: true,
                    minlength: 2
                },
				vorname : {
					required : true,
					minlength: 2
				},
				email: {
                    required: true,
                    email: true
                },
			},
			errorContainer: "#messageBox3",
            errorLabelContainer: "#messageBox3",
            wrapper: "li",
		});
		
		if(form.valid()){
			$.ajax({
                url: url + '/FinisherServices/posttariff2023',
                method: 'post',
                cache: false,
                data: {
                    //cmd: 'save',
                    // lauf : $('select[name=NamedesLaufes] > option:selected').val(),
                    anrede: $('select[name=anrede] > option:selected').val(),
                    laufundort: $('input[name=veranstaltung]').val(),
                    laufort: '',
                    startgeld: $('input[name=startgeld]').val(),
                    startdatum: $('input[name=veranstaltung-datum]').val(),
                    name: $('input[name=name]').val(),
                    vorname: $('input[name=vorname]').val(),
                    postleitzahl: $('input[name=postleitzahl]').val(),
                    ort: $('input[name=ort]').val(),
                    strasse: $('input[name=strasse]').val(),
                    nr: $('input[name=nr]').val(),
                    email: $('input[name=email]').val(),
                    telefon: '',
                    geburtsdatum: $('input[name=geburtsdatum]').val(),
                    zahlungsart: $('input[name=payment]:checked').val(),
                    beitrag: total,
                    amount: total,
                    iban: '',//$('input[name=iban]').val(),
                    bic: '',//$('input[name=bic]').val(),
                    clearingtype: '',//clearingtype,
                    request: 'authorization',
                    responsetype: 'JSON',
                    currency: 'EUR',
                    narrative_text: 'Finisherschutz',
                    onlinebanktransfertype: '',//onlinebanktransfertype,
                    dbguid: '',//$('#guid').val(),
                    nation: $('select[name=wohnsitz] > option:selected').val(),
                    tagderanmeldung: '',//$('input[name=tagderanmeldung]').val(),
					ganzesaison : $('input[name=inlineRadioOptions]:checked').val()
                },
                success: function (result) {
					if (result.Data) {
						window.location.href = "erfolgreich.html";
					}
				}
			});
		}
	})
})
